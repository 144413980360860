/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'password': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M868.593 403.832c-30.081-28.845-70.037-44.753-112.624-44.753h-490.02c-42.554 0-82.51 15.908-112.469 44.691-30.237 28.783-46.857 67.222-46.857 108.198v294.08c0 40.977 16.62 79.415 46.703 108.137C183.285 943.03 223.396 959 265.95 959h490.019c42.586 0 82.696-15.97 112.624-44.815 30.082-28.845 46.58-67.222 46.58-108.137v-294.08c-.001-40.915-16.498-79.29-46.58-108.136zM841.821 806.05c0 22.098-8.882 42.772-25.1 58.307-16.154 15.662-37.819 24.203-60.752 24.203h-490.02c-22.934 0-44.566-8.542-60.877-24.264-16.187-15.475-25.068-36.149-25.068-58.247v-294.08c0-22.036 8.881-42.772 25.193-58.308 16.187-15.537 37.82-24.14 60.754-24.14H755.97c22.933 0 44.598 8.603 60.753 24.14 16.218 15.536 25.1 36.272 25.1 58.308V806.05zM510.974 135.441c114.914 0 208.319 89.752 208.319 200.055h73.35C792.643 186.383 666.277 65 510.974 65c-155.334 0-281.7 121.384-281.7 270.497h73.35c0-110.302 93.436-200.055 208.35-200.055zM474.3 747.245h73.35V629.577H474.3v117.668z"/>'
  }
})
