import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    component: () => import('@/views/login')
  },
  {
    path: '/home',
    component: () => import('@/layout'),
    name: '系统配置',
    children: [
      {
        path: '/school-config',
        component: () => import('@/views/config/school'),
        name: '学校配置'
      },
      {
        path: '/dai-qu-config',
        component: () => import('@/views/config/dai-qu'),
        name: '代取信息'
      },
      {
        path: '/base-config',
        component: () => import('@/views/config/base'),
        name: '基本信息配置'
      },
      {
        path: '/message-config',
        component: () => import('@/views/config/message'),
        name: '消息'
      },
      {
        path: '/user-config',
        component: () => import('@/views/config/user'),
        name: '员工管理'
      },
      {
        path: '/consumer-config',
        component: () => import('@/views/config/consumer'),
        name: '用户管理'
      }
      // {
      //   path: '/system-config',
      //   component: () => import('@/views/config/system'),
      //   name: '系统设置'
      // }
    ]
  },
  {
    path: '/manage',
    component: () => import('@/layout'),
    redirect: '/yd',
    name: '运单管理',
    children: [
      {
        path: '/yd',
        name: '运单查询',
        component: () => import('@/views/manage/yd/index.vue')
      },
      {
        path: '/rk',
        name: '入库扫描',
        component: () => import('@/views/manage/rk/index.vue')
      },
      {
        path: '/ck',
        name: '出库扫描',
        component: () => import('@/views/manage/ck/index.vue')
      },
      {
        path: '/wt',
        name: '问题运单',
        component: () => import('@/views/manage/wt/index.vue')
      },
      {
        path: '/kh',
        name: '客户投诉',
        component: () => import('@/views/manage/kh/index.vue')
      },
      {
        path: '/dq',
        name: '代取运单',
        component: () => import('@/views/manage/dq/index.vue')
      },
      {
        path: '/zx',
        name: '在线寄件',
        component: () => import('@/views/manage/zx/index.vue')
      },
      {
        path: '/pj',
        name: '派件扫描',
        component: () => import('@/views/manage/pj/index.vue')
      },
      {
        path: '/yhj',
        name: '优惠券',
        component: () => import('@/views/manage/yhj/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
